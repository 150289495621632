import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { getTimestampFromMilliseconds } from '../../../functions/actions/orders';

export default function Counter(props: { orderTimeUtc: string }) {
    const { orderTimeUtc } = props;

    const orderTimeMoment = moment.utc(orderTimeUtc); // Getting the moment once to use for future calculations

    const originalTimeStamp = getTimestampFromMilliseconds(moment().diff(orderTimeMoment)); // Calculating the timestamp on load
    var [countTime, setCountTime] = useState(originalTimeStamp);

    useEffect(() => {
        const timer = setInterval(() => {
            const timeDiffInMilliseconds = moment().diff(orderTimeMoment); // The difference between now and ordertime in milliseconds
            setCountTime(getTimestampFromMilliseconds(timeDiffInMilliseconds));
        }, 1000);
        return () => clearInterval(timer);
    }, [orderTimeMoment]);

    return (
        <span className="order-time-counter">{countTime}</span>
    )
}