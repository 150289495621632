import React from 'react';

import Checkbox from '../../Checkbox';

import { kdsStates } from '../../../../settings/app';

import { IElement } from '../../../../interfaces/IElement';

export default function ElementChildChild(props: { elementChildChild: IElement }) { // A list of choices for the element
    const { elementChildChild } = props;
    
    var isInProduction = elementChildChild.state && elementChildChild.state.value === kdsStates.PRODUCTION ? true : false; // To overrule the checked state when all elements are run at once 
    var disabled = !elementChildChild.state || elementChildChild.state.value !== kdsStates.PRODUCTION;

    return (
        <div className={disabled ? "element-child-child-container disabled" : "element-child-child-container"}>

            <Checkbox isChecked={isInProduction} isInactive={disabled} />

            <p key={elementChildChild.id} className="element-child-child">
                {elementChildChild.amount} x {elementChildChild.externalName}
            </p>

        </div>
    )
}