import { IFilter } from "../../interfaces/IFilter";
import { IOrder } from "../../interfaces/IOrder";
import { IElement } from "../../interfaces/IElement";

import moment from "moment";

import { kdsStates } from "../../settings/app";
import { IIngredient } from "../../interfaces/IIngredient";

export function getTimestampFromMilliseconds(s: number) { // Converting the millisecond diff to a timestamp
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    function doubleDigits(n: number) {
        return n.toString().length === 1 ? "0" + n : n;
    }

    return doubleDigits(hrs) + ':' + doubleDigits(mins) + ':' + doubleDigits(secs);
}

function filterForModules(orders: Array<IOrder>, moduleIds: Array<string>) {
    var filteredOrders = orders;

    function filtering(order: IOrder) {
        const orderModuleId = order.moduleId;
        return !orderModuleId || moduleIds.indexOf(orderModuleId) > -1;
    }

    // Filtering for the chosen modules
    filteredOrders = orders.filter(order => filtering(order));

    return filteredOrders;
}

function filterForTime(orders: Array<IOrder>) {
    var filteredOrders = orders;

    function filtering(order: IOrder) {
        const orderReadyMoment = moment(order.orderReadyTime);
        const isToday = orderReadyMoment.isSame(moment(), 'day');

        return isToday;
    }

    // Filtering orders before today or after the filter time 
    filteredOrders = orders.filter(order => filtering(order));

    return filteredOrders;
}

export function filterOrderList(orders: Array<IOrder>, filterOptions: IFilter) {
    var filteredOrders = orders;

    // Filtering for time so we only show future orders (+ yesterday's if any are leftover)
    filteredOrders = filterForTime(filteredOrders);

    if (filterOptions) {
        if (filterOptions.modules && filterOptions.modules.length > 0) { // If no modules, we show all orders
            filteredOrders = filterForModules(filteredOrders, filterOptions.modules);
        }
    }

    return filteredOrders;
}

export const getElementIds = (elements: Array<IElement>) => { // Incl products and nested choices 
    var ids: Array<number> = [];
    var inQueue: Array<number> = [];
    var inProduction: Array<number> = [];

    function ingredientsState(ingredients: Array<IIngredient>) {
        var state = kdsStates.QUEUE
        ingredients.forEach(function(ingredient) {
            if (ingredient.state && ingredient.state.value) { // No state means it's a modifier 
                state = ingredient.state.value;
            }
        });
        return state;
    }

    const addElementIds = (elements: Array<IElement>) => {
        elements.forEach(function(element) {

            if (element.state) { // No state means it's a modifier 
                ids.push(element.id);

                var state = element.state.value;
                if (ingredientsState(element.ingredients) === kdsStates.PRODUCTION) { state = kdsStates.PRODUCTION; }

                if (state === kdsStates.QUEUE) { inQueue.push(element.id); }
                if (state === kdsStates.PRODUCTION) { inProduction.push(element.id); }
            } 

            if (element.children) { addElementIds(element.children); }
        });
    }

    addElementIds(elements);
    return { ids, inQueue, inProduction };
}

export const addStateToOrders = (orders: Array<IOrder>) => { // Setting the state for the whole order based on element states
    var ordersWithStates: Array<IOrder> = [];

    orders.forEach(function(order) {
        var orderWithState = Object.assign({}, order);

        const orderElementIds = getElementIds(order.elements);
        var elementIdsInProduction = orderElementIds.inProduction; 

        const stateId = elementIdsInProduction.length ? kdsStates.PRODUCTION : undefined;
        orderWithState.stateId = stateId;
        ordersWithStates.push(orderWithState);
    });

    return ordersWithStates;
}