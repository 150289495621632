import React from 'react';

import Order from './Order';
import { IOrder } from '../../interfaces/IOrder';

export default function OrderList(props: { filteredOrders: Array<IOrder> }) {
    const { filteredOrders } = props;

    return (
        <div className="order-list list">

            { filteredOrders && filteredOrders.map(function(order: IOrder) {
                return <Order 
                            key={order.id} 
                            order={order}
                        />
            })}
            
        </div>
    )
}