import React, { useState } from 'react';

import moment from 'moment';

import { useAppSelector } from '../redux/hooks';

import NavTabs from './NavTabs';
import OrderList from './Orders/OrderList';
import SummaryList from './Summary/SummaryList';

import { addStateToOrders, filterOrderList } from '../functions/actions/orders';
import { kdsStates } from '../settings/app';

import { IOrder } from '../interfaces/IOrder';

export default function OrderView() {
    const { orders, filter } = useAppSelector((state) => state); 

    var [chosenNavTabName, setChosenNavTabName] = useState("orders");

    const ordersWithStates = addStateToOrders(orders); // Adding states to orders to avoid looping through multiple times 
    const productionOrders = ordersWithStates.filter((o: IOrder) => o.stateId === kdsStates.PRODUCTION );

    const filteredProductionOrders = (productionOrders: Array<IOrder>) => filterOrderList(productionOrders, filter).sort((a: IOrder, b: IOrder) => moment(a.lastModifiedUtc).valueOf() - moment(b.lastModifiedUtc).valueOf());
    
    var filteredOrders = filteredProductionOrders(productionOrders);

    return (
        <div className="order-view">

            <NavTabs 
              chosenNavTabName={chosenNavTabName} 
              handleNavTabChange={(newValue) => setChosenNavTabName(newValue)} 
            />

            { chosenNavTabName === "orders" && 
                <OrderList filteredOrders={filteredOrders} /> 
            }

            { chosenNavTabName === "summary" && 
                <SummaryList filteredOrders={filteredOrders} /> 
            }

          </div>
      );
}
