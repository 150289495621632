import React from 'react';

import { useAppSelector } from '../../redux/hooks';

import { IOrder } from '../../interfaces/IOrder';

import ProductOrder from './Product/ProductOrder';
import IngredientOrder from './Ingredient/IngredientOrder';

export default function Order(props: { order: IOrder }) {
    const { order } = props;
    const { instance } = useAppSelector((state) => state); 

    const viewType = instance && instance.settings.kdsViewOption === 0 ? "product": "ingredient";

    return (
        <div className="order-container list-item">
            
            { viewType === "product" && 
                <ProductOrder 
                    key={order.id} 
                    order={order} 
                />
            } 

            { viewType === "ingredient" && 
                <IngredientOrder
                    key={order.id} 
                    order={order}
                />
            } 

        </div>
    )
}