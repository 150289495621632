import React from 'react';

export default function Checkbox(props: { isInactive: boolean, isChecked: boolean }) {
    const { isInactive, isChecked } = props;

    return (
        <div className={ isInactive ? "checkbox disabled" : "checkbox"}>
            <input type="checkbox" checked={isChecked} readOnly />
			<div className="checkmark-container">
				{ isChecked && 
					<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
					</svg>
				}
			</div>
        </div>
    )
}