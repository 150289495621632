import React from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { styled } from '@mui/material/styles';

import { tabStyles } from '../functions/mui';

const StyledTabs = styled(Tabs)(tabStyles);

let navTabOptions = [
    { "name": "orders", "titleKey": "common:Orders" },
    { "name": "summary", "titleKey": "common:Summary" }
];

export default function NavTabs(props: {  chosenNavTabName: string, handleNavTabChange: (updatedNavTabName: string) => void }) {
    const { chosenNavTabName } = props;
    const { handleNavTabChange } = props;

    const { t } = useTranslation();

    const chosen = navTabOptions.find(n => n.name === chosenNavTabName);

    return (
        <div className="nav-tabs-container">
            <StyledTabs
                value={chosen}
                onChange={(event, newValue) => handleNavTabChange(newValue ? newValue.name : "orders")}
                variant="fullWidth"
                aria-label="navigation tabs"
                >
                { navTabOptions.map(function(option, index) {
                    return <Tab key={index} value={option} label={t(option.titleKey)} />
                })}
            </StyledTabs>
        </div>
      );
}
