import React from 'react';

import Checkbox from '../../Checkbox';
import Element from '../Elements/Element';

import { IElement } from '../../../../interfaces/IElement';

import { kdsStates } from '../../../../settings/app';

export default function Product(props: { element: IElement }) {
    const { element } = props;

    var isInProduction = element.state && element.state.value === kdsStates.PRODUCTION ? true : false; 

    return (
        <div className="order-element-container">

            <Checkbox isChecked={isInProduction} isInactive={!element.state || element.state.value === kdsStates.QUEUE} />

            <Element element={element} />

        </div>
    )
}