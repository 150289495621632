import React from 'react';
import { Route, Switch } from 'react-router-dom'

import Main from './views/Main';
import Callback from './views/Callback';
import Silent from './views/Silent';

export default function App() {

  	return (
	    <Switch>
			<Route exact path={"/"} component={Main} />
			<Route exact path={"/callback"} component={Callback} />
			<Route exact path={"/silent"} component={Silent} />
	    </Switch>
  	)
}