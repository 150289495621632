import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../redux/hooks';

import { redirectAuth } from '../functions/auth';

export default function Header(props: { customerName: string, authenticated: boolean, handleInstanceClick: () => void }) {
    const { customerName, authenticated } = props;
    const { handleInstanceClick } = props;

    const { t } = useTranslation();

    const instance = useAppSelector((state) => state.instance);

    return (
        <div className="header-section">

            <div className="header">

                <div className="header-title-section">
                    <h1 className="header-title">{t("header:Title")} <span className="title-customer-name">{customerName}</span></h1>
                </div>

                <div className="header-information-section">

                    { instance && <p className="instance-toggle header-item" onClick={handleInstanceClick}>{instance.settings.name ? instance.settings.name : t("common:DefaultInstanceName")}</p> }

                    <button className="button header-item" onClick={() => redirectAuth(authenticated)}>{t("header:LogOut")}</button>
                </div>
            
            </div>

        </div>
    )
}