import React from 'react';

import Element from '../Orders/Product/Elements/Element';

import { IElement } from '../../interfaces/IElement';

export default function SummaryElement(props: { element: IElement }) {
    const { element } = props;

    return (
        <div className="summary-element">
          <Element element={element} />
        </div>
      );
}
