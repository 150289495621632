import React from 'react';

import Checkbox from '../Checkbox';

import { IIngredient } from '../../../interfaces/IIngredient';

import { kdsStates } from '../../../settings/app';

export default function Ingredient(props: { ingredient: IIngredient }) {
    const { ingredient } = props;

    var isInProduction = ingredient.state && ingredient.state.value === kdsStates.PRODUCTION ? true : false; // To overrule the checked state when all elements are run at once 

    return (
        <div className="order-element-container">

            <Checkbox isChecked={isInProduction} isInactive={!ingredient.state || ingredient.state.value === kdsStates.QUEUE} />

            <div className="element">
                <p className="element-name">{ingredient.summarize ? ingredient.amount : `${ingredient.count || 1} x ${ingredient.amount} `} {ingredient.measurementUnit} {ingredient.externalName}</p>
            </div>

        </div>
    )
}