import { IElement } from "../../interfaces/IElement";
import { IIngredient } from "../../interfaces/IIngredient";
import { IOrder } from "../../interfaces/IOrder";
import { kdsStates } from "../../settings/app";

function identicalProducts(element1: IElement, element2: IElement) {
    var identical = false;

    if (element1.externalId === element2.externalId) { // Same product 
        if (element1.children.length === 0 && element2.children.length === 0) {
            identical = true;
        } else if (element1.children.length === element2.children.length) { // Same amount of modifiers - check the choices 

            const getElementExternalIds = (elements: Array<IElement>) => { // Incl products and nested choices 
                var externalIds: Array<number> = [];
            
                const addElementIds = (elements: Array<IElement>) => {
                    elements.forEach(function(element) {
                        externalIds.push(element.externalId);
                        if (element.children) { addElementIds(element.children); }
                    });
                }
            
                addElementIds(elements);
                return externalIds;
            }

            const element1Ids = getElementExternalIds([element1]);
            const element2Ids = getElementExternalIds([element2]);

            identical = element1Ids.length === element2Ids.length && element1Ids.every((value, index) => value === element2Ids[index]);
        }
    }
    return identical;
}

export let summarizedElements = function f(orders: Array<IOrder>) { // Summarizing all elements in an order 
    var summarizedElementsList: Array<IElement> = [];

    if (orders && orders.length) {

        const summarizedProducts = (elements: Array<IElement>) => { 
            var summarized: Array<IElement> = [];
        
            const summarize = (elements: Array<IElement>) => {
                return elements.reduce((accumulatedList, eachElement) => {
                    if (eachElement.state && eachElement.state.value === kdsStates.PRODUCTION) {
                        const indexOfElementInSummaryList = accumulatedList.findIndex(e => identicalProducts(e, eachElement));
                        if (indexOfElementInSummaryList > -1) {
                            var updatedElement = Object.assign({}, accumulatedList[indexOfElementInSummaryList]);
                            updatedElement.amount = updatedElement.amount + eachElement.amount;        
                            accumulatedList[indexOfElementInSummaryList] = updatedElement;
                        } else {
                            accumulatedList.push(eachElement);
                        }
                    }

                    return accumulatedList;
                }, [...summarized]); // initialize the new Array with the contents of summarize
            }
        
            summarized = summarize(elements);
            return summarized;
        }

        // An array with all elements 
        var elements: Array<IElement> = [];
        orders.forEach(o => elements = elements.concat(o.elements));

        summarizedElementsList = summarizedProducts(elements);
    }

    return summarizedElementsList;
}

export const summarizedIngredients = (elements: Array<IElement>, includeQueueElements: boolean) => { 
    var summarized: Array<IIngredient> = [];

    const summarize = (ingredients: Array<IIngredient>) => {
        return ingredients.reduce((accumulatedList, eachIngredient) => {
            const indexOfIngredientInSummaryList = accumulatedList.findIndex((eachSummarizedIngredient) => eachSummarizedIngredient.externalId === eachIngredient.externalId && (eachSummarizedIngredient.summarize ? true : eachSummarizedIngredient.amount === eachIngredient.amount));
            if (indexOfIngredientInSummaryList === -1) {
                accumulatedList.push(eachIngredient);
            } else { // Ingredient already exists in the array, so add the amounts or count        
                var updatedIngredient = Object.assign({}, accumulatedList[indexOfIngredientInSummaryList]);
                const summarize = updatedIngredient.summarize;

                if (summarize) { // Adding up the amount, so we have a total amount 
                    updatedIngredient.amount = updatedIngredient.amount + eachIngredient.amount;
                } else { // Counting, so we have x count of the amount
                    updatedIngredient.count = updatedIngredient.count ? updatedIngredient.count + 1 : 2;
                }

                if (updatedIngredient.ids) {
                    updatedIngredient.ids.push(eachIngredient.id);
                } else {
                    updatedIngredient.ids = [updatedIngredient.id, eachIngredient.id];
                }
                accumulatedList[indexOfIngredientInSummaryList] = updatedIngredient;
            }
            return accumulatedList;
        }, [...summarized]); // initialize the new Array with the contents of summarize
    }

    const ingredientList = (elements: Array<IElement>) => {
        var list: Array<IIngredient> = [];

        function generateIngredientList(elements: Array<IElement>) {
            elements.forEach(element => {
                const { ingredients, children } = element;
                if (children && children.length) { 
                    generateIngredientList(children); 
                }
                if (ingredients && ingredients.length) { list = list.concat(ingredients); }
            });
        }

        generateIngredientList(elements);
        return list;
    }

    const finalIngredientList: Array<IIngredient> = ingredientList(elements);

    const queueIngredientList: Array<IIngredient> = summarize(finalIngredientList.filter(i => i.state && i.state.value === kdsStates.QUEUE));
    const productionIngredientList: Array<IIngredient> = summarize(finalIngredientList.filter(i => i.state && i.state.value === kdsStates.PRODUCTION));

    summarized = includeQueueElements ? queueIngredientList.concat(productionIngredientList) : productionIngredientList;
    return summarized;
}