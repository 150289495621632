import React from 'react';

import { IIngredient } from '../../interfaces/IIngredient';

export default function SummaryIngredient(props: { ingredient: IIngredient }) {
    const { ingredient } = props;

    return (
        <div className="summary-element">
          <div className="element">
            <p className="element-name">{ingredient.summarize ? ingredient.amount : `${ingredient.count || 1} x ${ingredient.amount} `} {ingredient.measurementUnit} {ingredient.externalName}</p>
          </div>
        </div>
      );
}
