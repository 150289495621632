import React from 'react';

import ElementChildChild from './ElementChildChild';

import { IElement } from '../../../../interfaces/IElement';

export default function ElementChild(props: { elementChild: IElement }) { // A list of choices for the element
    const { elementChild } = props;
    
    return (
        <div className="element-child-container">
            { elementChild.children && elementChild.children.map(function(elementChildChild) {
                return <ElementChildChild key={elementChildChild.id} elementChildChild={elementChildChild} />
            })}
        </div>
    )
}