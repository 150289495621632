import React from 'react';

import { useAppDispatch } from '../../../redux/hooks';
import { updateIngredientState } from '../../../redux/actions';

import { kdsStates } from '../../../settings/app';

import IngredientList from './IngredientList';
import OrderHeader from '../OrderHeader/OrderHeader';

import { summarizedIngredients } from '../../../functions/actions/summary';

import { IOrder } from '../../../interfaces/IOrder';
import { IIngredient } from '../../../interfaces/IIngredient';

export default function IngredientOrder(props: { order: IOrder }) {
    const { order } = props;

    const dispatch = useAppDispatch();
    
    var ingredientsToShow: Array<IIngredient> = summarizedIngredients(order.elements, true);
    var ingredientIdsInProduction = ingredientsToShow.filter(e => e.state && e.state.value === kdsStates.PRODUCTION).map(e => e.id);

    function handleRunClick() {
        var toRun: Array<number> = [];

        ingredientsToShow.forEach(function(ingredient) {
            toRun = toRun.concat(ingredient.ids ? ingredient.ids : [ingredient.id]);
        })

        if (toRun && toRun.length) {
            dispatch(updateIngredientState(toRun, order.id));
        }
    }

    return (
        <div className="order ingredient">

            <OrderHeader 
                order={order}

                disabled={ingredientsToShow.length !== ingredientIdsInProduction.length}
                handleRunClick={handleRunClick}
            />

            <IngredientList 
                ingredients={ingredientsToShow}
            />

        </div>
    )
}