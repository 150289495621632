import type { AppDispatch } from './store'

import { getModules } from '../functions/actions/redux';
import { get } from '../functions/api';
import { kds_url, kds_websocket } from '../settings/app'

import { IFilter } from '../interfaces/IFilter';
import { IInstance } from '../interfaces/IInstance';
import { IOrder } from '../interfaces/IOrder';

export interface AppAction { payload?: any, type?: string }

export const SET_LOADING = "SET_LOADING";
export const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: {
    loading: loading
  }
});

export const SET_INSTANCE = "SET_INSTANCE"; // Setting the instance in websocket and triggering a save 
export const setInstance = (instance: IInstance | undefined) => ({
  type: SET_INSTANCE,
  payload: {
    instance: instance
  }
});

export const SET_FILTER = "SET_FILTER";
export const setFilter = (filter: IFilter) => ({
  type: SET_FILTER,
  payload: {
    filter: filter
  }
});

export const LOAD_MODULES = "LOAD_MODULES";
export const loadModules = () => async (dispatch: AppDispatch) => { // AppDispatch
  var modules = await getModules();
  dispatch({ type: LOAD_MODULES, payload: { "modules": modules } });
  return { type: LOAD_MODULES, payload: { "modules": modules } };
}

export const LOAD_ORDERS = "LOAD_ORDERS";
export const loadOrders = (instanceId: string) => async (dispatch: AppDispatch) => { // AppDispatch
  const response = await get(kds_url + "/order?instanceId=" + instanceId);
  dispatch({ type: LOAD_ORDERS, payload: { "orders": response ? response : [] } });
  return { type: LOAD_ORDERS, payload: { "orders": response ? response : [] } };
}

// SIGNALR ACTIONS

export const CONNECT_TO_WEBSOCKET = "CONNECT_TO_WEBSOCKET";
export const connectToWebsocket = (instance: IInstance) => ({
  type: CONNECT_TO_WEBSOCKET,
  payload: {
    url: kds_websocket,
    instance: instance
  }
});

export const ORDER_RECEIVED = "ORDER_RECEIVED";
export const orderReceived = (order: IOrder) => ({
  type: ORDER_RECEIVED,
  payload: {
    order: order
  }
});

export const UPDATE_ELEMENT_STATE = "UPDATE_ELEMENT_STATE";
export const updateElementState = ( elementIds: Array<number>, orderId: number ) => ({
  type: UPDATE_ELEMENT_STATE,
  payload: {
    elementIds: elementIds,
    orderId: orderId
  }
});

export const UPDATE_INGREDIENT_STATE = "UPDATE_INGREDIENT_STATE";
export const updateIngredientState = ( elementIds: Array<number>, orderId: number ) => ({
  type: UPDATE_INGREDIENT_STATE,
  payload: {
    elementIds: elementIds,
    orderId: orderId
  }
});

export const KDS_STATE_UPDATED = "KDS_STATE_UPDATED";
export const kdsStateUpdated = ( elementIds: Array<number>, orderId: number, updatedState: number, type: string ) => ({
  type: KDS_STATE_UPDATED,
  payload: {
    elementIds: elementIds,
    orderId: orderId,
    updatedState: updatedState,
    type: type
  }
});