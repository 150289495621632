import React from 'react';
import { useTranslation } from 'react-i18next';

import SummaryElement from './SummaryElement';
import SummaryIngredient from './SummaryIngredient';

import { IOrder } from '../../interfaces/IOrder';
import { IElement } from '../../interfaces/IElement';

import { summarizedElements, summarizedIngredients } from '../../functions/actions/summary';
import { IIngredient } from '../../interfaces/IIngredient';

export default function SummaryList(props: { filteredOrders: Array<IOrder> }) { // Split product and ingredient summary 
  const { filteredOrders } = props; 

  const { t } = useTranslation();

  var elements: Array<IElement> = [];
  filteredOrders.forEach(o => elements = elements.concat(o.elements));

  const sortAlphabetically = (a: IElement, b: IElement) => a.externalName.toUpperCase() < b.externalName.toUpperCase() ? -1 : a.externalName.toUpperCase() > b.externalName.toUpperCase() ? 1 : 0;
  const summarizedElementsList = summarizedElements(filteredOrders).sort((a: IElement, b: IElement) => a.externalName && b.externalName ? sortAlphabetically(a, b) : 0);

  const summarizedIngredientsList = summarizedIngredients(elements, false).sort((a: IIngredient, b: IIngredient) => a.sortOrder && b.sortOrder && a.sortOrder < b.sortOrder ? -1 : 1);
  
  return (
    <div className="summary-list-section">

      <div className="summary-list">
        <p className="summary-list-title">{t("orders:Products")}</p>

        <div className="summary-list-content">
          { summarizedElementsList.map(function(summaryElement) {
            return <SummaryElement key={summaryElement.id} element={summaryElement} />
          })}
        </div>
      </div>

      <div className="summary-list">
        <p className="summary-list-title">{t("orders:Ingredients")}</p>

        <div className="summary-list-content">
          { summarizedIngredientsList.map(function(summaryIngredient) {
            return <SummaryIngredient key={summaryIngredient.id} ingredient={summaryIngredient} />
          })}
        </div>
      </div>

    </div>
  );
}
